import axios from "axios";
import openSocket from "socket.io-client";

export const isTestnet = true;
//const base_url = "https://apitest.myearth.id";
const base_url = "https://stage-apiv2.myearth.id"
const api =
  isTestnet === true
    ? "https://stage-apiv2.myearth.id"
    : "https://apiv2.myearth.id";
const socketURL =
  isTestnet === true
    ? "https://stage-socketv2.myearth.id"
    : "https://socketv2.myearth.id";
// const socket = openSocket(socketURL, {
//   //transports: ["websocket"],
// });
const socket = openSocket(socketURL)
let socketId;
socket.on("connect", () => {
  socketId = socket.id;
  console.log(socketId)
});
const apiKey = "UKURGTREFATPSYJ";
const ssiBaseUrl = "https://ssi-gbg.myearth.id";
const authorizationKey = "Api-Key 01a41742-aa8e-4dd6-8c71-d577ac7d463c";
let apiAuthorization = "fae2622d-7b73-4fc6-a536-202cabe75187";

const externalFunctions = {
  /**
   * Returns API Key for vendor
   *
   */
  async getApiKey() {
    return apiKey;
  },
  /**
   * API for generating QR Code
   * @example response
   * qrData=`{"apikey":"werty","encryptionkey":"1234567","reqNo":"qwertyuuytr","sessionKey":"wertyuytresd" }`
   */

  async registerVendor() {
    return new Promise((resolve, reject) => {
      try {
        var config = {
          method: "post",
          url: `${base_url}/customer/registerVendor`,
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": authorizationKey,
          },
          data: {
            vendorName: "vendor",
            docsRequired: ["ID", "DL"],
            webhook_url: "http://localhost:3000/user/getUser",
            adminKey: "Rejolut@1008",
            partnerOrgId: ""
          },
        };
        axios(config, { crossdomain: true })
          .then((res) => {
            return resolve(res.data);
          })
          .catch((e) => {
            console.log("This is e ", e);
            return reject("unable to get secret Token");
          });
      } catch (e) {
        console.log("Error in qr Code ", e);
        return reject(e);
      }
    });
  },

  // New function to generate QR
  async generateQrCodeNest(signature, apiKey, nonce, timestamp, requestType) {
    return new Promise((resolve, reject) => {
      try {
        var config = {
          headers: {
            authkey: apiAuthorization,
            "x-request-signature-sha-256": "" + signature + "",
            "api-key": apiKey,
            nonce: nonce,
            timestamp: timestamp,
          },
        };
        axios
          .get(
            `${base_url}/customer/generateQr?socketId=${socketId}&requestType=${requestType}`,
            config,
            { crossdomain: true }
          )
          .then((res) => {
            console.log(res.data)
            return resolve(res.data);
          })
          .catch((e) => {
            console.log("This is e ", e);
            return reject("unable to get secret Token");
          });
      } catch (e) {
        console.log("Error in qr Code ", e);
        return reject(e);
      }
    });
  },

  //verifier function
  async verifiergenerateqr(requestType) {
    return new Promise((resolve, reject) => {
      // console.log("apiKey ", apiKey)
      try {
        if (!apiKey) {
          return new Error("apiKey not availaible");
        }
        if (!socketId) {
          return new Error("socketId not availaible");
        }
        let config = {
          headers: {
            authkey: apiAuthorization,
          },
        };
        //get session key from server and store the socketID with session key in db , to be used further during service Provider API to emit
        axios
          .get(
            `${api}/ssi/generateQrCode?apiKey=${apiKey}&socketId=${socketId}&requestType=${requestType}`,
            config,
            { crossdomain: true }
          )
          .then((response) => {
            console.log("response", response);
            if (response.data.code === 400) {
              let errorString = response.data.message
                ? response.data.message
                : "Something went wrong";
              return reject(errorString);
            }
            return resolve(response.data.result);
          })
          .catch((e) => {
            console.log("This is e ", e);
            return reject("unable to get secret Token");
          });
      } catch (e) {
        console.log("Error in qr Code ", e);
        return reject(e);
      }
    });
  },
  async generateqr(requestType, issuerDid) {
    return new Promise((resolve, reject) => {
      // console.log("apiKey ", apiKey)
      try {
        if (!apiKey) {
          return new Error("apiKey not availaible");
        }
        if (!socketId) {
          // console.log("socket not availaible try to reload page")
          return new Error("socketId not availaible");
        }
        //generate issuerDid
        //get session key from server and store the socketID with session key in db , to be used further during service Provider API to emit
        axios
          .get(
            `${api}/ssi/generateQrCode?apiKey=${apiKey}&socketId=${socketId}&requestType=${requestType}&issuerDid=${issuerDid} `,
            // { headers: {  "authKey": "fae2622d-7b73-4fc6-a536-202cabe75187" } },
            { crossdomain: true }
          )
          .then((response) => {
            if (response.data.code === 400) {
              let errorString = response.data.message
                ? response.data.message
                : "Something went wrong";
              return reject(errorString);
            }
            return resolve(response.data.result);
          })
          .catch((e) => {
            console.log("This is e ", e);
            return reject("unable to get secret Token");
          });
      } catch (e) {
        console.log("Error in qr Code ", e);
        return reject(e);
      }
    });
  },

  async createIssuerDid() {
    // var axios = require('axios');

    var data = "";
    var config = {
      method: "get",
      url: ssiBaseUrl + "/api/issuer/did",
      headers: {
        "X-API-KEY": authorizationKey,
      },
      data: data,
    };
    return new Promise((resolve, reject) => {
      try {
        axios(config)
          .then(function (response) {
            console.log(response);
            resolve(JSON.stringify(response.data.data));
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (e) {
        console.log("Error while getJourneyResult: ", e);
        reject(e);
      }
    });
  },

  async getUserCreditnals(credential) {
    var data = JSON.stringify(credential);

    var config = {
      method: "post",
      url: ssiBaseUrl + "/api/issuer/createVerifiableCred",
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": authorizationKey,
      },
      data: data,
    };
    return new Promise((resolve, reject) => {
      try {
        axios(config)
          .then(function (response) {
            resolve(response.data.data);
            socket.emit("credentials", response.data.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (e) {
        console.log("Error while getJourneyResult: ", e);
        reject(e);
      }
    });
  },

  async issuerDidgenerateqr(requestType, encryptionkey) {
    return new Promise((resolve, reject) => {
      console.log("coming");

      // console.log("apiKey ", apiKey)
      try {
        let config = {
          headers: {
            authKey: "fae2622d-7b73-4fc6-a536-202cabe75187",
          },
        };
        if (!apiKey) {
          return new Error("apiKey not availaible");
        }
        console.log("Socket=====================" + socketId);
        if (!socketId) {
          // console.log("socket not availaible try to reload page")
          return new Error("socketId not availaible");
        }
        //get session key from server and store the socketID with session key in db , to be used further during service Provider API to emit
        axios
          .get(
            `${api}/ssi/generateQrCode?apiKey=${apiKey}&socketId=${socketId}&requestType=${requestType}&encryptionkey=${encryptionkey}`,
            { headers: { authKey: "fae2622d-7b73-4fc6-a536-202cabe75187" } },
            { crossdomain: true }
          )
          .then((response) => {
            console.log(response);
            if (response.data.code === 400) {
              let errorString = response.data.message
                ? response.data.message
                : "Something went wrong";
              return reject(errorString);
            }
            return resolve(response.data.result);
          })
          .catch((e) => {
            console.log("This is e ", e);
            return reject("unable to get secret Token");
          });
      } catch (e) {
        console.log("Error in qr Code ", e);
        return reject(e);
      }
    });
  },
  /**
     * Start Listening for service provider name
     * @param {* function } cb callback for storing response data
     * @example O/P Successfull
     * {
            "serviceProvider": "FCart ",
        }
        @example O/P Unsuccessfull 
        Error object
     */
  async listenForServiceProviderResponse(cb) {
    socket.on(`sendServiceProvider`, (data) => {
      cb(null, data);
      console.log(data)
    });
  },
  /**
   * Socket listening for user Data after approval from user through app
   * @param {*} cb callback for data response
   * @returns {*function} returns a callback with newreq object
   * @example
   * // Response successfull
   * newreq:{
   * "pressed":false,
   * "userEmail":"srvo@gmail.com",
   * "userMobileNo":"+916361887698",
   * "fname":"Sarvottam",
   * "dob":"05121993",
   * "emailVerified":true
   * "mobileVerified":false
   * "score":250
   * }
   * //Unsuccessfull
   * * newreq:{
   * "pressed":true,
   * }
   */
  async listenForUserData(cb) {
    console.log("listenForServiceProviderResponse   ");
    socket.on(`userdata`, (data) => {
      if (data && !data.error) {
        console.log("listenForUserData : ", data);
        cb(null, data);
      }
    });
  },

  //verifer functions

  async verifyCreditnals(
    userCredential,
    userPublicKey,
    userSignature,
    userVerifyParams
  ) {
    console.log(userCredential, userPublicKey, userSignature, userVerifyParams);
    let data = [];
    let verifyUrl;
    if (userVerifyParams) {
      var keys = Object.keys(userVerifyParams);
      console.log("keys", keys);
      var value = Object.values(userVerifyParams);
      for (var i = 0; i < keys.length; i++) {
        for (var j = 0; j < value.length; j++) {
          if (i == j) {
            data.push(keys[i] + "%3D" + value[j]);
          }
          console.log("data", data);
        }
      }
    }
    if (data.length > 0) {
      var config = {
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": authorizationKey,
          publicKey: userPublicKey,
          signature: userSignature,
        },
        params: {
          credentials: userCredential,
        },
      };
      verifyUrl = `${ssiBaseUrl}/api/verifier/verify?verifyParams=${data}`;
    } else {
      var config = {
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": authorizationKey,
          publicKey: userPublicKey,
          signature: userSignature,
        },
        params: {
          credentials: userCredential,
        },
      };
      verifyUrl = `${ssiBaseUrl}/api/verifier/verify`;
    }
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(verifyUrl, config)
          .then(function (response) {
            resolve(response.data);
            console.log(JSON.stringify(response.data.success));
          })
          .catch(function (error) {
            resolve(error);
            console.log(error);
          });
      } catch (e) {
        console.log("Error while getJourneyResult: ", e);
        reject(e);
      }
    });
  },

  async verifier_generateqr(requestType) {
    return new Promise((resolve, reject) => {
      // console.log("apiKey ", apiKey)
      try {
        if (!apiKey) {
          return new Error("apiKey not availaible");
        }
        if (!socketId) {
          return new Error("socketId not availaible");
        }
        let config = {
          headers: {
            authkey: apiAuthorization,
          },
        };
        //get session key from server and store the socketID with session key in db , to be used further during service Provider API to emit
        axios
          .get(
            `${api}/ssi/generateQrCode?apiKey=${apiKey}&socketId=${socketId}&requestType=${requestType}`,
            config,
            { crossdomain: true }
          )
          .then((response) => {
            console.log("response", response);
            if (response.data.code === 400) {
              let errorString = response.data.message
                ? response.data.message
                : "Something went wrong";
              return reject(errorString);
            }
            return resolve(response.data.result);
          })
          .catch((e) => {
            console.log("This is e ", e);
            return reject("unable to get secret Token");
          });
      } catch (e) {
        console.log("Error in qr Code ", e);
        return reject(e);
      }
    });
  },
  /**
       * Start Listening for service provider name
       * @param {* function } cb callback for storing response data
       * @example O/P Successfull
       * {
            "serviceProvider": "FCart ",
          }
          @example O/P Unsuccessfull 
          Error object
       */
  async verifier_listenForServiceProviderResponse(cb) {
    socket.on(`sendServiceProvider`, (data) => {
      cb(null, data);
    });
  },
  async verifier_listenForUserData(cb) {
    // console.log("verifier_listenForUserDataResponse  ")
    socket.on(`userdata`, (data) => {
      if (data && !data.error) {
        console.log("verifier_listenForUserData : ", data);
        cb(null, data);
      }
    });
  },

  async verifier_listenForServiceProviderResponse(cb) {
    socket.on(`sendServiceProvider`, (data) => {
      cb(null, data);
    });
  },
};
export default externalFunctions;