import React, { Component } from "react";
import openSocket from "socket.io-client";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
//import "../src/components/insuranceUI/insurance1.css"
import Subscribe from "./components/Subscribe/subs";
import insurance1 from "./components/insuranceUI/insurance1";

// export const isTestnet = true;
// const socketURL =
//   isTestnet === true
//     ? "https://socket-stagev2.myearth.id"
//     : "https://socketv2.myearth.id";
// const socket = openSocket(socketURL, {
//   transports: ["websocket", "polling", "flashsocket"],
// });
// let socketId;
// socket.on("connect", () => {
//   socketId = socket.id;
//   console.log(`I'm connected with the back-end`);
// });



class App extends Component {


  render() {
    return (
      <div className="App">


          <div className="content">
            <Router>
              <Switch>
              <Route exact path="/" component={insurance1} />
              {/* <Route path="/generateCred" component={Baseui2} />
              <Route path="/uploadDoc" component={DocumentMetadata} />
              <Route path="/user" component={NewUser}/>
              <Route path="/admin/" component={Admin} />
              <Route path="/certificate/death/:userName" component={Project} />
              <Route path="/certificate/employment/:userName" component={IDCertificate} />
              <Route path="/certificate/marriage/:userName" component={Training} />
              <Route path="/certificate/birth/:userName" component={Professional} />
              <Route path="/certificate/ServiceNow/" component={ServiceNow} /> */}
              <Route path="/transactions" component={Subscribe} />
              </Switch>
            </Router>
          </div>
      </div>
    );
  }
}

export default App;
