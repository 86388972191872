import React, { useState, useEffect, Component } from 'react';
import "./insurance1.css"


import carimg from "../../insurance_imgs/car.jpg";
import life from "../../insurance_imgs/fam_img1.jpeg";
import home from "../../insurance_imgs/home.jpg";
import health from "../../insurance_imgs/health.jpg";
import business from "../../insurance_imgs/business.jpg";
import travel from "../../insurance_imgs/travel.jpg";

import {
  faTwitter,
  faFacebook,
  faYoutube,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";






import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import KycFlow from '../../kycQr';
import data from "../../api";
import 'bootstrap/dist/css/bootstrap.min.css';


import { PDFDocument } from 'pdf-lib';
import VerifyDoc from "../../verifyDoc"


const fs = require('fs');
const MySwal = withReactContent(Swal);
// export const isTestnet = true;
// const socketURL =
//     isTestnet === true
//         ? "https://socket-stagev2.myearth.id"
//         : "https://socketv2.myearth.id";
// const socket = openSocket(socketURL, {
//     transports: ["websocket", "polling", "flashsocket"],
// });
// let socketId;
// socket.on("connect", () => {
//     socketId = socket.id;
//     //console.log(`I'm connected with the back-end`);
// });


const {
    listenForUserData
} = data;

const ssiBaseUrl = "https://ssi-test.myearth.id";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

const insurance1 = () => {

    const getWalletDoc = () => {
        try {

        
            listenForUserData(async (err, res) => {
                let bytes = res.userDetails || res.newreq;
                //                 sessionStorage.setItem('profileData', JSON.stringify(bytes));
                // console.log('Got Session Data')
                console.log("Upload bytes", bytes.documentVc, bytes.selectedFields);
                const formattedFields = bytes.selectedFields.map(field => `${field.name}=${field.value}`);

console.log(formattedFields);
                if(bytes){
                    // Show loading swal
        const loadingSwal = Swal.fire({
          title: 'Loading',
          text: 'Verifying...',
          allowOutsideClick: false,
          showConfirmButton: false, 
          onBeforeOpen: () => {
              Swal.showLoading();
          }
      });

                  console.log("For SDD Check, no document upload needed!")
    //Add a function for verifySDD and use swal alert for the response
    const verifyRes = await verifySDD(bytes.documentVc, formattedFields, bytes.publicKey)
    console.log("VerifySDD", verifyRes)

    loadingSwal.close();
    if (verifyRes.success) {
      

      // Success alert
      Swal.fire({
          title: "Verification Successful",
          text: "The verification was successful.",
          icon: "success",
          willClose: () => {
            window.location.reload();
        }
      });
    } else {
     

      // Failed alert
      Swal.fire({
          title: "Verification Failed",
          text: "The verification failed. Please try again.",
          icon: "error",
          willClose: () => {
            window.location.reload();
        }
      });
    }
                }else{
                 

                    Swal.fire({
                        title: "Verification Failed",
                        text: "The verification failed. Please try again.",
                        icon: "error",
                        willClose: () => {
                          window.location.reload();
                      }
                    });
                }
        
            });
        } catch (error) {
          
            console.log('Document not found');
        }
    };


    const verifySDD = async (credential, verifyParams, publicKey) => {
        try {
      
            //const signature = await createUserSignature();
      
            const data = { "credentials": credential, "verifyParams": verifyParams }
            //const data = { "credentials": credential }
            console.log(data)
            const config = {
                method: 'post',
                url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
                headers: {
                    'X-API-KEY': authorizationKey,
                    'publicKey': publicKey,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };
      
            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));
      
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
      }


      const handleKYCFlowComplete = () => {
        // Call the getWalletDoc function
    
    
        getWalletDoc();
    
    };

const redirectToHome= () => {
        window.location.href = "/";
      }

  return (
    <div className="App">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div
            className="container collapse navbar-collapse pull-right container"
            id="navbarTogglerDemo01"
          >
            <div className="clickable" onClick={() => redirectToHome()}>
              <img
                src={require("../../Logo-EarthID-white.png")}
                alt="logo"
                className="logo"
              />{" "}</div>
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">
                  Insurance
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Services
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Portfolio
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  News
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Contact
                </a>
              </li>
            </ul>

            {/* {localStorage.user_name ? (
              <div>
                <label className="welcomeLabel">{`Welcome, ${localStorage.getItem(
                  "user_name"
                )}`}</label>
              </div>
            ) : null} */}

            <div className="profileAccess">
              {window.location.pathname == "/" ? (
                <div>
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.verifyQrCodeData}
                  >
                    Verify Credentials
                  </button> */}
                  <KycFlow onComplete={handleKYCFlowComplete}/>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </nav>
        <header>
          <section class="home1">
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <div class="home1line"></div>
                  <h3>
                    Insurance for the better family <br />
                    <span>life.</span>
                  </h3>

                  <p>
                    Choose policies according to your requirements, no worries
                    we have you covered.
                  </p>
                  <a class="left1" href="#">
                    Get Started Now!
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section class="home2">
            <div class="container">
              <h2>We're not about money. We're about trust. </h2>
              <div class="row">
                <div class="extra-div col-md-4">
                  <h2>01</h2>
                  <h2>Assured Service</h2>
                  <hr />
                  <h3>
                    No need to worry about getting timely service, we are always
                    with you.
                  </h3>
                </div>
                <div class="extra-div col-md-4">
                  <h2>02</h2>
                  <h2>Get Free Quote</h2>
                  <hr />
                  <h3>
                    We provide expert advice on various policy types and there
                    benefits.
                  </h3>
                </div>
                <div class="extra-div col-md-4">
                  <h2>03</h2>
                  <h2>Fast & Relaiable</h2>
                  <hr />
                  <h3>
                    A dedicated team is here just to provide you fast and smooth
                    service whenever needed.
                  </h3>
                </div>
              </div>
            </div>
          </section>

          <section class="ourservices">
            <div class="container">
              <h3>Our Services</h3>
              <div class="servline"></div>
              <h1>
                We're covering all the
                <br /> Insurance Fields
              </h1>
              <div class="row">
                <div class="col-md-3 servdiv">
                  <img src={carimg}></img>
                  <h2>Car Insurance</h2>
                  <p>Prevent your car from any damages.</p>
                </div>

                <div class="col-md-3 servdiv">
                  <img src={life}></img>
                  <h2>Life Insurance</h2>
                  <p>A life insurance for your family and loved ones.</p>
                </div>

                <div class="col-md-3 servdiv">
                  <img src={home}></img>
                  <h2>Home Insurance</h2>
                  <p>Secure your home from all threats.</p>
                </div>

                <div class="col-md-3 servdiv">
                  <img src={health}></img>
                  <h2>Health Insurance</h2>
                  <p>Get regular health check ups and benefits.</p>
                </div>

                <div class="col-md-3 servdiv">
                  <img src={business}></img>
                  <h2>Business Insurance</h2>
                  <p>We got you covered even in your losses.</p>
                </div>

                <div class="col-md-3 servdiv">
                  <img src={travel}></img>
                  <h2>Travel Insurance</h2>
                  <p>Explore the world with no worries.</p>
                </div>
              </div>
            </div>
          </section>

          <section class="home3">
            <div class="container">
              <div class="col-md-7 home3top">
                <h4>Why Choose?</h4>
                <div class="servline"></div>
                <h2>Few reasons for people choosing our service</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5"></div>
              <div class="types col-md-4">
                <h2>01</h2>
                <div class="servline"></div>
                <h3>Fast & Easy Process</h3>
                <p>
                  Cut through the waiting and complicated process, we got it
                  covered.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="types1 col-md-4">
                <h2>02</h2>
                <div class="servline"></div>
                <h3>Control Over Policy</h3>
                <p>
                  See every spend better and track expenditures in one place.
                  Your money isn’t a mystery anymore.
                </p>
              </div>
              <div class="col-md-5"></div>
            </div>
            <div class="row">
              <div class="col-md-5"></div>
              <div class="types2 col-md-4">
                <h2>03</h2>
                <div class="servline"></div>
                <h3>Everything you need, in one app</h3>
                <p>
                  Payments? Check. Spend analytics? Check. Savings goals? Check.
                  Life just got a lot better.
                </p>
              </div>
            </div>
          </section>

          <footer class="footerr">
            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <h2>Get an insurance quote to get started!</h2>
                </div>
                <div class="social col-md-8">
                  <h2>Follow Us:</h2>

                  <a href="https://twitter.com/earthid_ssi" target="blank">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a href="https://www.facebook.com/myearth.id/" target="blank">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/myearthid/"
                    target="blank"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCaRjLyN4I_0UrK3NL4zU1Vg/featured"
                    target="blank"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                  <a href="https://www.instagram.com/myearthid/" target="blank">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a href="#">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </div>
              </div>
            </div>
          </footer>
         
        </header>
      </div>
  )
}

export default insurance1
